<template>
	
	<div>
		<span class="tag subtitle has-text-weight-semibold has-text-centered" v-if="titre_hover">{{titre_hover.libelle}}</span>
		<img src="@/assets/plan.svg">
		<template v-for="endroit in $store.state.endroits">
			<router-link :style="endroit.coordonnees"
			v-on:mouseover.native="titre_hover = endroit" 
			v-on:mouseout.native="titre_hover = false" 
			:to = "{name:'Endroit',params:{id:endroit.id,slug:endroit.slug}}">
			{{endroit.libelle}}
		</router-link>
	</template>
</div>
</template>

<script>
	export default {
		data() {
			return {
				titre_hover:false,
			}
		},
	}
</script>

<style scoped lang="scss">
div {
	margin:1em auto;
	width: 80%;
	height: 0;
	padding-top: 43%;
	position: relative;
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	a {
		position: absolute;
		overflow: hidden;
		text-indent: -1000px;
		transition: background 0.2s ease;
		&:hover {
			background: rgba(0, 0, 0, 0.5)
		}
	}
	span {
		position: absolute;
		right: 70%;
		top: 62%;
		font-size: 15px;
	}
}

</style>

